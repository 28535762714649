import { Avatar } from "@mui/material";
import SmallTickIcon from "components/Vectors/SmallTickIcon";

function UserDisplay({
 name,
 img,
 badge,
 email,
 handle,
 salesperson,
 imgRounded = true,
 truncate = false,
 max_width = "100%",
 size = "medium",
 textWhite = false,
 reverse = false,
 className = "",
 onClick,
}) {
 return (
  <div
   className={`flex ${size === "small" ? "gap-x-2" : "gap-x-4"} items-center ${
    reverse && "flex-row-reverse"
   } ${className}`}
  >
   {badge ? (
    <div className="relative h-[40px] w-[40px]">
     <Avatar
      src={img}
      alt="avatar"
      className={`w-full ${onClick && "cursor-pointer"}`}
      sx={{
       borderRadius: imgRounded ? "50%" : "4px",
       width: size === "medium" ? 40 : size === "small" ? 30 : 50,
       height: size === "medium" ? 40 : size === "small" ? 30 : 50,
      }}
      onClick={onClick}
     />
     <div className="absolute -top-1 -right-1">
      <SmallTickIcon TooltipTitle="verified" tooltipPlacement="top" color="#8937CE" />
     </div>
    </div>
   ) : (
    <Avatar
     src={img}
     sx={{
      borderRadius: imgRounded ? "50%" : "4px",
      width: size === "medium" ? 40 : size === "small" ? 30 : 50,
      height: size === "medium" ? 40 : size === "small" ? 30 : 50,
     }}
     className={`${onClick && "cursor-pointer"}`}
     onClick={onClick}
    />
   )}
   <div>
    <p
     style={{ maxWidth: max_width }}
     className={`${truncate && `truncate`} ${size === "medium" || size === "small" ? "text-sm" : "text-base"} ${
      textWhite ? "text-white" : "text-secondary"
     }  font-medium capitalize`}
    >
     {name}
    </p>
    {email && (
     <p
      className={`${truncate && "truncate"}  ${size === "medium" || size === "small" ? "text-xs" : "text-sm"} mt-1 ${
       textWhite ? "text-white" : "text-grey"
      }  `}
     >
      {email}
     </p>
    )}
    {handle && (
     <p
      className={`${truncate && "truncate"}  ${size === "medium" || size === "small" ? "text-xs" : "text-sm"} mt-1 ${
       textWhite ? "text-white" : "text-grey"
      }  `}
     >
      {handle}
     </p>
    )}
    {salesperson && (
     <p
      className="truncate text-xs mt-1 text-grey"
     >
      Converted by - {salesperson}
     </p>
    )}
   </div>
  </div>
 );
}

export default UserDisplay;
